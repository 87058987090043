import {Injectable} from '@angular/core';
import {multiVaultAbi} from '../abi/multiVault.abi';
import {multiVaultAbiV4} from '../abi/multiVaultV4.abi';
import {VaultContractTypeEnum} from '@shared/enums/vault-contract-type.enum';
import {receiverVaultAbi} from '../abi/receiverVault.abi';
import {NetworkChainIdEnum} from '@shared/enums/networks.enum';
import {VaultWithdrawModalRespInterface} from '@shared/modals/vault-withdraw-modal/vault-withdraw-modal-resp.interface';
import {BehaviorSubject, from, shareReplay} from 'rxjs';
import {BigNumber} from '@ethersproject/bignumber';
import {erc20abi} from '../abi/erc20.abi';
import {parseUnits} from '@ethersproject/units';
import {TokenInterface} from '@shared/interfaces/token.interface';
import {Chain} from "viem";
import {
    arbitrum,
    avalanche,
    base,
    blast,
    bsc,
    fantom,
    haqqMainnet,
    harmonyOne,
    mainnet,
    optimism,
    polygon,
    zkSync
} from "viem/chains";
import {ethers} from "ethers";
import {UniversalProvider} from "@walletconnect/universal-provider";
import {Loader} from "@shared/modules/custom-loader/models/decorators";
import {createWeb3Modal, defaultWagmiConfig} from '@web3modal/wagmi'
import {getAccount, reconnect, watchAccount} from "@wagmi/core";
import {saakuru, vtru} from "@core/custom-networks-chain";


declare const window: any;


@Injectable()
export class MetamaskService {

    provider: any;
    appProvider: any;
    walletAddress: string | null;
    chainId;
    private connectedMetamaskAddress = new BehaviorSubject<string | null>(null);
    private currentChain = new BehaviorSubject<NetworkChainIdEnum | null>(null);
    web3modal;
    unwatch: () => void;

    projectId: string;

    constructor(
    ) {
    }

    initConnectWallet() {
        this.projectId = 'd9de65ff7e703b11bc06a4b158eb22b0';
        const chains = [arbitrum, mainnet, polygon, avalanche, bsc, fantom, harmonyOne, optimism as any, base, zkSync, blast, haqqMainnet, saakuru, vtru] as [Chain, ...Chain[]];

        const metadata = {
            name: 'Web3Modal',
            description: 'Web3Modal Example',
            url: 'https://web3modal.com',
            icons: ['https://avatars.githubusercontent.com/u/37784886']
        }
        const wagmiConfig = defaultWagmiConfig(
            {
                chains,
                enableInjected: true,
                projectId: this.projectId,
                metadata
            });

        this.web3modal = createWeb3Modal({
            wagmiConfig,
            projectId: this.projectId,
            chainImages: {
                1666600000: 'assets/icons/networks/harmony.png',
                8453: 'assets/icons/networks/base.png',
                81457: 'assets/icons/networks/blast.png',
                11235: 'assets/icons/networks/haqq.png',
                1490: 'assets/icons/networks/vtru.png',
                7225878: 'assets/icons/networks/saakuru.png',
                // 295: 'assets/icons/networks/hedera.png',
            },
            themeVariables: {
                '--w3m-border-radius-master': '3px',
            },
        })

        this.unwatch = watchAccount(wagmiConfig, {
            onChange: (account, prevAccount) => {
                if (account?.isConnected && account?.chainId && (account?.chainId !== prevAccount?.chainId || account?.address !== prevAccount.address)) {
                    this.walletAddress = account.address;
                    this.connectedMetamaskAddress.next(this.walletAddress);
                    this.chainId = account?.chainId;
                    this.currentChain.next(account?.chainId);
                    if (!this.provider) {
                        this.connectProvider();
                    }
                } else if (!account?.chainId) {
                    this.walletAddress = null;
                    this.connectedMetamaskAddress.next(null);
                    this.chainId = null;
                    this.currentChain.next(account?.chainId);
                }
            }
        });

        this.web3modal.subscribeEvents((newEvent) => {
            if (newEvent?.data?.event === 'CONNECT_SUCCESS') {
                const account = getAccount(wagmiConfig);
                this.walletAddress = account.address;
                this.connectedMetamaskAddress.next(this.walletAddress);
                if (account?.chainId) {
                    this.chainId = account?.chainId;
                    this.currentChain.next(account?.chainId);
                }
                this.connectProvider();
            }

            if (newEvent?.data?.event === 'MODAL_LOADED') {
                if (JSON.parse(wagmiConfig?.storage['wagmi.store'])?.state?.connections?.value?.length) {
                    const rec = reconnect(wagmiConfig);
                }
            }
        })

    }

    getConnectedAddress() {
        return this.connectedMetamaskAddress.asObservable().pipe(shareReplay(1));
    }

    getConnectedChain() {
        return this.currentChain.asObservable().pipe(shareReplay(1));
    }

    connectProvider() {
        if (window.ethereum) {
            this.appProvider = window.ethereum;
            this.provider = new ethers.providers.Web3Provider(this.appProvider);
        } else {
            UniversalProvider.init({
                projectId: this.projectId,
                relayUrl: 'wss://relay.walletconnect.com'
            }).then(provider => {
                this.appProvider = provider;
                this.provider = new ethers.providers.Web3Provider(this.appProvider);
            });
        }
    }

    async sendTransaction(addressTo: string, amount: string) {
        // @ts-ignore
        return this.provider.getGasPrice().then((gasPrice) => {
                    const value = parseUnits(amount, 18).toHexString();
                    return {
                        gasPrice: BigNumber.from(gasPrice).toHexString(),
                        gas: BigNumber.from(this.chainId === NetworkChainIdEnum.arbitrum ? '300000' : '21000').toString(),
                        to: addressTo,
                        from: this.walletAddress,
                        value,
                    };
                }).then((tx) => {
                    return this.appProvider.request({
                        method: 'eth_sendTransaction',
                        params: [tx],
                    });
                });
    }

    async sendTokens(addressTo: string, value: string, token: TokenInterface) {

        const signer = this.provider.getSigner();
        const fnAbi = erc20abi.filter((p) => p.name === 'transfer');
        const erc20 = new ethers.Contract(token.address, fnAbi, signer);
        const amount = ethers.utils.parseUnits(value, token.decimals);
        // @ts-ignore
        return this.provider.getGasPrice().then(gasPrice => {
            // @ts-ignore
            return erc20.transfer(addressTo, amount, {
                gasPrice,
                gasLimit: '300000',
            });
        });
    }

    async executeWithdraw(
        contractAddress: string,
        vaultType: VaultContractTypeEnum,
        data: VaultWithdrawModalRespInterface,
        isEth = false,
    ) {
        if (isEth) {
            return this.withdrawEth(contractAddress, data.receiverAddress, vaultType);
        } else {
            return this.withdrawToken(
                contractAddress,
                data.tokenAddress,
                data.receiverAddress,
                vaultType,
            );
        }
    }

    async withdrawEth(
        contractAddress: string | null,
        receiverAddress: string,
        type: VaultContractTypeEnum,
    ): Promise<any> {
        if (!contractAddress) {
            return;
        }
        const signer = this.provider.getSigner();
        const abi = type === VaultContractTypeEnum.receiver ? receiverVaultAbi : multiVaultAbi;
        const fnAbi = abi.filter((p) => p.name === 'withdrawETH');
        const contract = new ethers.Contract(contractAddress,fnAbi, signer);

        return this.provider.getGasPrice().then(gasPrice => {
            if (window.ethereum) {
                // @ts-ignore
                return contract.withdrawETH(receiverAddress, {
                    gasPrice: gasPrice,
                    gasLimit: '300000',
                });
            }
            // @ts-ignore
            contract.withdrawETH(receiverAddress, {
                gasPrice: gasPrice,
                gasLimit: '300000',
            });
            return true;
        });
    }

    async withdrawToken(
        contractAddress: string | null,
        tokenAddress: string,
        receiverAddress: string,
        type: VaultContractTypeEnum,
    ): Promise<any> {
        if (!contractAddress) {
            return;
        }
        const signer = this.provider.getSigner();
        const abi = type === VaultContractTypeEnum.receiver ? receiverVaultAbi : multiVaultAbi;
        const fnAbi = abi.filter((p) => p.name === 'withdrawTokens');
        const contract = new ethers.Contract(contractAddress,fnAbi, signer);

        return this.provider.getGasPrice().then(gasPrice => {
            if (window.ethereum) {
                // @ts-ignore
                return contract.withdrawTokens(tokenAddress, receiverAddress, {
                    gasPrice: gasPrice,
                    gasLimit: '300000',
                })
            }
            // @ts-ignore
            contract.withdrawTokens(tokenAddress, receiverAddress, {
                gasPrice: gasPrice,
                gasLimit: '300000',
            })
            return true;

        });
    }

    @Loader()
    setMainReceiver(newReceiver: string, contractAddress: string | null): Promise<any> {
        if (!this.walletAddress) {
            throw new Error('To change main receiver you need connect to Metamask wallet first');
        }
        const signer = this.provider.getSigner();
        const contract = new ethers.Contract(contractAddress,multiVaultAbiV4, signer);
        // @ts-ignore
        return from(this.provider.getGasPrice().then(gasPrice => {
            if (window.ethereum) {
                // @ts-ignore
                return contract.setReceiver(newReceiver, {
                    from: this.walletAddress,
                    gasPrice: gasPrice,
                    gasLimit: '300000',
                }).then(tx => tx.wait(1))
            }
            // @ts-ignore
            contract.setReceiver(newReceiver, {
                from: this.walletAddress,
                gasPrice: gasPrice,
                gasLimit: '300000',
            });
            return true;
        }));
    }

}
