export enum DexNamesEnum {
    quickswap = 'quickswap',
    uniswap = 'uniswap',
    pancakeswap = 'pancakeswap',
    joeswap = 'joeswap',
    dmmswap = 'dmmswap',
    spookyswap = 'spookyswap',
    babyswap = 'babyswap',
    sushiswap_eth = 'sushiswap_eth',
    sushiswap_bsc = 'sushiswap_bsc',
    sushiswap_pol = 'sushiswap_pol',
    sushiswap_heco = 'sushiswap_heco',
    sushiswap_harmony = 'sushiswap_harmony',
    biswap = 'biswap',
    mdexswap = 'mdexswap',
    'bsc-testswap' = 'bsc-testswap',
    blhswap = 'blhswap',
    pangolinswap = 'pangolinswap',
    apeswap = 'apeswap',
    'knightswap' = 'knightswap',
    // 'goerli-testswap' = 'goerli-testswap',
    uniswap_v3 = 'uniswap_v3',
    uniswap_v3_pol = 'uniswap_v3_pol',
    uniswap_v3_opt = 'uniswap_v3_opt',
    uniswap_v3_arb = 'uniswap_v3_arb',
    uniswap_v3_celo = 'uniswap_v3_celo',
    uniswap_v3_base = 'uniswap_v3_base',
    uniswap_v3_mantle = 'uniswap_v3_mantle',
    oreoswap = 'oreoswap',
    zyberswap = 'zyberswap',
    thena = 'thena',
    alienswap = 'alienswap',
    camelot = 'camelot',
    zyberswap_v3 = 'zyberswap_v3',
    pancakeswap_v3 = 'pancakeswap_v3',
    shibaswap = 'shibaswap',
    'uniswap_v3_bsc' = 'uniswap_v3_bsc',
    quickswap_v3 = 'quickswap_v3',
    dexSwap = 'dexSwap',
    'joeswap_v2_ava' = 'joeswap_v2_ava',
    'joeswap_v2_arb' = 'joeswap_v2_arb',
    pancakeswap_zksync = 'pancakeswap_zksync',
    pancakeswap_v3_zksync = 'pancakeswap_v3_zksync',
    synthswap = 'synthswap',
    synthswap_v3 = 'synthswap_v3',
    camelot_v3 = 'camelot_v3',
    sushiswap_arb = 'sushiswap_arb',
    sushiswap_v3_arb = 'sushiswap_v3_arb',
    balancer_eth = 'balancer_eth',
    balancer_pol = 'balancer_pol',
    sushiswap_v3_core = 'sushiswap_v3_core',
    raydium = 'raydium',
    raydium_v3 = 'raydium_v3',
    sushiswap_base = 'sushiswap_base',
    thruster_v2 = 'thruster_v2',
    thruster_v3 = 'thruster_v3',
    cyberblast_v2 = 'cyberblast_v2',
    cyberblast_v3 = 'cyberblast_v3',
    uniswap_base = 'uniswap_base',
    alien_base = 'alien_base',
    alien_v3_base = 'alien_v3_base',
    // saucerswap_hedera = 'saucerswap_hedera',
    // saucerswap_v3_hedera = 'saucerswap_v3_hedera',
    balancer_arb = 'balancer_arb',
    aerodrome_base = 'aerodrome_base',
    dedust = 'dedust',
    sushiswap_haqq = 'sushiswap_haqq',
    sushiswap_v3_haqq = 'sushiswap_v3_haqq',
    curve_eth = 'curve_eth',
    curve_pol = 'curve_pol',
    vtru = 'vtru',
    taffy = 'taffy',
    meteora = 'meteora',
    hypecoin = 'hypecoin',
    sunswap = 'sunswap',
    stonfi = 'stonfi',
    sunpump = 'sunpump',
    kim = 'kim',
}

export enum ArbitrageDexNames {
    pancakeswapV2 = 'pancakeswapV2',
    pancakeswapV3 = 'pancakeswapV3',
    uniswapV2 = 'uniswapV2',
    uniswapV3 = 'uniswapV3',
    sushiswapV2 = 'sushiswapV2',
    sushiswapV3 = 'sushiswapV3',
}

export enum CoingeckoDexNamesEnum {
    quickswap = 'quickswap',
    uniswap = 'uniswap_v2',
    uniswap_v3 = 'uniswap_v3',
    uniswapV3Pol = 'uniswap_v3_polygon_pos',
    uniswapV3Opt = 'uniswap_v3_optimism',
    uniswapV3Arb = 'uniswap_v3_arbitrum',
    pancakeswap = 'pancakeswap_new',
    pancakeswap_v3 = 'pancakeswap-v3-bsc',
    joeswap = 'traderjoe',
    // joeV2Arb = 'traderjoe-v2-arbitrum',
    // joeV2Ava = 'traderjoe-v2-avalanche',
    // joeV2Bsc = 'traderjoe-v2-bsc',
    spookyswap = 'spookyswap',
    babyswap = 'babyswap',
    sushiswap = 'sushiswap',
    // sushiswapArb = 'sushiswap_arbitrum',
    // sushiswapAva = 'sushiswap_avalanche',
    // sushiswapFtm = 'sushiswap_fantom',
    sushiswapBsc = 'sushiswap_bsc',
    sushiswapPol = 'sushiswap_polygon_pos',
    sushiswapHarmony = 'sushiswap_harmony',
    biswap = 'biswap',
    mdexswap = 'mdex',
    // mdexswapBsc = 'mdex_bsc',
    pangolinswap = 'pangolin',
    apeswapBsc = 'apeswap_bsc',
    // apeswapPol = 'apeswap_polygon',
    knightswap = 'knightswap',
    oreoswap = 'oreoswap',
    zyberswap = 'zyberswap',
    alienBaseV3 = 'alien-base',
    alienBaseV2 = 'alien-base',
}
