import {Component, OnInit} from '@angular/core';
import {RolesEnum} from '@shared/enums/roles.enum';
import {AuthService} from '@auth0/auth0-angular';
import {PermissionService} from '@core/permission.service';
import {filter, take} from 'rxjs';
import {DestroySubscription, Helper} from '@helpers';
import {AlertTypesEnum} from '@shared/enums/alert-types.enum';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {environment} from "../../../environments/environment";
import {MetamaskService} from "@core/metamask.service";

declare const window: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent extends DestroySubscription implements OnInit {
    private readonly defaultLogoPath = '/assets/images/logo.svg';

    isAuth = false;
    email: string | undefined;
    rolesEnum = RolesEnum;
    enableLogo = true;
    hover = false; //template variable
    logoUrl: string;
    env = environment;
    // web3modal;
    onlyOnePermission: boolean;

    provider = window.ethereum || null;
    projectId: string;

    constructor(
        private auth: AuthService,
        private metamaskService: MetamaskService,
        private notification: NzNotificationService,
        public permissions: PermissionService,
    ) {
        super();
        this.metamaskService.initConnectWallet();
    }

    ngOnInit(): void {
        this.auth.isAuthenticated$.subscribe((isAuth) => {
            this.isAuth = isAuth;
            if (!isAuth) {
                this.login();
            }
        });
        this.permissions.initPermissions();
        this.permissions.isReady
            .pipe(
                filter((r) => r),
                take(1),
            )
            .subscribe(() => {
                if (this.permissions.isInRole(RolesEnum.ViewClientDashboard)) {
                    this.enableLogo = false;
                }
                this.onlyOnePermission = !(
                    this.permissions.isInRoles([RolesEnum.ArbProjectMember, RolesEnum.ArbProjectAdmin]) &&
                    this.permissions.isInRoles([RolesEnum.ProjectAdmin, RolesEnum.ProjectMember])
                ) && !this.permissions.isInRole(RolesEnum.TenantAdmin);
                this.email = this.permissions.userEmail;
                this.logoUrl = this.getLogoUrl();
            });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.metamaskService.unwatch();
    }

    login() {
        this.auth.loginWithRedirect({
            appState: { target: '/authcallback' },
        });
    }

    onLogoClick() {
        this.permissions.navigateByPermissions();
    }

    copyAddress(e: Event, address: string | undefined | null) {
        e.preventDefault();
        e.stopPropagation();

        Helper.copyToBuffer(address as string);
        this.notification.create(AlertTypesEnum.Success, 'Success', `The address copied.`);
    }

    private getLogoUrl(): string {
        let result = '';

        if (this.permissions.userEmail) {
            result = this.permissions.logoUrl || this.defaultLogoPath;
        }

        return result;
    }

    logout() {
        this.auth.logout({ logoutParams: {returnTo: document.location.origin }});
    }
}
