import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthcallbackComponent } from './page/authcallback/authcallback.component';
import { AuthGuard } from '@auth0/auth0-angular';
import {IconsSamplesComponent} from "@shared/modules/icons/components/icons-samples/icons-samples.component";

const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'tenants',
                loadChildren: () =>
                    import('./page/tenants/tenants.module').then((m) => m.TenantsModule),
            },
            // {
            //     path: 'arbitrage/tenants',
            //     loadChildren: () => import('./page/arbitrage/arbitrage.module').then(m => m.ArbitrageModule),
            // },
            {
                path: 'dashboard-view',
                loadChildren: () => import('./page/profile-dashboard/profile-dashboard.module').then(m => m.ProfileDashboardModule),
            },
        ],
    },
    {
        path: 'authcallback',
        component: AuthcallbackComponent,
    },
    {
        path: 'error',
        loadChildren: () => import('./page/error/error.module').then((m) => m.ErrorModule),
    },
    {
        path: 'icons',
        component: IconsSamplesComponent,
    },
    {
        path: '**',
        redirectTo: 'error/404',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
