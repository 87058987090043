import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {BasePermission} from "@shared/directives/permissions/base-permission";
import {PermissionService} from "@core/permission.service";
import {PermissionsEnum} from "@shared/enums/permissions.enum";

@Directive({ selector: '[appShowPermissionsFor]' })
export class ShowPermissionsForDirective extends BasePermission {


    @Input() set appShowPermissionsFor(input: PermissionsEnum) {
        const hasPermission = this.checkPermissions(input);
        this.toggleVisibility(hasPermission);
    }

    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }
}
