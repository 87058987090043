import {DexNamesEnum} from '../enums/dex-names.enum';
import {ProfileStatusEnum} from '../enums/profile-status.enum';
import {VaultModesEnum} from '../enums/vault-modes.enum';
import {PriceCurrencyEnum, RangeStatusEnum, RangeTypeEnum} from "@shared/enums/range.enum";
import {AddProfileWizardStepsEnum} from "../enums/add-profile-wizard-steps.enum";
import {formatUnits} from "@ethersproject/units";
import {CexNamesEnum, NetworkNamesEnum} from "@shared/enums/networks.enum";

export interface ProfilesInterface extends ProfileInterface{
    sellersCount: number;
    buyersCount: number;
    receiversCount: number;
    receiverAddress: string;
    feePercent: number;
    maxBalanceToSend: number;
    vaultMode: VaultModesEnum;
    vaultAddress: string | null;
    withdrawer: string | null;
    createdAt: Date;
    updatedAt: Date;
    version: number;
}

export interface ProfileInterface {
    readonly id: number;
    readonly tokenId: number;
    readonly profileName: string;
    readonly dex: DexNamesEnum;
    readonly cex: CexNamesEnum;
    readonly baseTokenName: string;
    readonly quoteTokenName: string;
    readonly nativeTokenName: string;
    status: ProfileStatusEnum;
    readonly vaultMode: VaultModesEnum;
    readonly wizardSetupStep: AddProfileWizardStepsEnum;
    readonly tradersCount: number;
    readonly minTxCountPerTrader: number;
    readonly maxTxCountPerTrader: number;
    readonly poolFee?: number;
}

export interface CexProfileBaseInterface {
    readonly id: number;
    readonly tokenId: number;
    readonly profileName: string;
    readonly cex: CexNamesEnum;
    status: ProfileStatusEnum;
    readonly wizardSetupStep: AddProfileWizardStepsEnum;
    vaultMode?: VaultModesEnum | null;
}

export interface CexProfileInterface extends CexProfileBaseInterface{
    readonly baseTokenName: string;
    readonly quoteTokenName: string;
}

export interface CexProfileExtendedInterface extends  CexProfileBaseInterface {
    readonly baseToken: string;
    readonly quoteToken: string;
    readonly verifiedTelegramUsers: string[];
    readonly telegramTags: string[];
    readonly additionalAccount: boolean;
    readonly accountPermissions: {[key: string]: boolean};
    readonly additionalAccountPermissions: {[key: string]: boolean};
}

export interface ProfileFullInfoInterface extends ProfileInterface, ProfileDataInterface {
    readonly loaded: boolean;
    readonly toQuote: number | null;
    readonly toUSD: number | null;
}

export interface CexProfileFullInfoInterface extends CexProfileInterface, ProfileDataInterface {
    readonly loaded: boolean;
    readonly toQuote: number | null;
    readonly toUSD: number | null;
}

export interface ProfilePricesInfoInterface {
    readonly id: number;
    readonly profileType: ProfileType,
    readonly toQuote: number;
    readonly toUSD: number;
}

export class GetCexTokensPriceResDto {
    public priceQuote: number;
    public priceUsd: number;
}

export class GetCexTokensPrice {
    public base: string;
    public quote: string;
    public exchange: CexNamesEnum;

    constructor(
        profile: CexProfileExtendedInterface,
    ) {
        this.base = profile.baseToken;
        this.quote = profile.quoteToken;
        this.exchange = profile.cex;
    }
}

export interface ProfileMoveInterface {
    readonly toProjectId: number,
    readonly profilesIds: number[],
}

export interface ProfileDataStrategiesInterface {
    readonly min: string;
    readonly max: string;
    readonly status: RangeStatusEnum;
    readonly type: RangeTypeEnum;
    readonly priceCurrency: PriceCurrencyEnum;
}

export interface ProfileStrategiesStatInterface {
    readonly active: number;
    readonly activeNotInRange: number;
    readonly inactive: number;
    readonly tooltip: string;
}

export interface ProfileDataInterface {
    readonly id: number;
    readonly strategies: ProfileDataStrategiesInterface[];
    readonly tokens: ProfileBalanceClass;
    readonly proxy?: ProfileBalanceClass;
    readonly gas?: ProfileGasClass;
    readonly decimals?: ProfileDecimalsInterface;
}

export class ProfileSummaryClass {
    constructor() {
    }

    public formatTokenAmount(amount, decimals): number {
        if(!amount)return 0;
        return +formatUnits(amount, decimals);
    }
}

export interface ProfileDataDtoInterface {
    readonly strategies: ProfileDataStrategiesInterface[];
    readonly mainContractBalances: ProfileMainBalancesInterface;
    readonly receiversContractBalances: ProfileReceiversBalancesInterface;
    readonly gasWalletBalances: GasWalletBalanceInterface;
    readonly decimals: ProfileDecimalsInterface;
}

export interface CexProfileDataDtoInterface {
    readonly strategies: ProfileDataStrategiesInterface[];
    readonly balances: CexProfileBalancesInterface;
}

export interface CexProfileBalancesInterface {
    readonly baseBalance: string;
    readonly quoteBalance: string;
}

export interface ProfileDecimalsInterface {
    readonly base: number;
    readonly quote: number;
    readonly native: number;
}

export interface ProfileReceiversBalancesInterface {
    readonly baseReceiversAmount: string;
    readonly quoteReceiversAmount: string;
}

export interface ProfileMainBalancesInterface {
    readonly vaultBaseBalance: string;
    readonly vaultQuoteBalance: string;
}

export class ProfileBalanceClass extends ProfileSummaryClass {
    public readonly base: number;
    public readonly quote: number;
    constructor(
        base,
        quote,
        baseDecimal?: number,
        quoteDecimal?: number,
    ) {
        super();
        this.base = baseDecimal && this.formatTokenAmount(base, baseDecimal || 18) || base;
        this.quote = quoteDecimal && this.formatTokenAmount(quote, quoteDecimal || 18) || quote;
    }
}

export class ProfileGasClass extends ProfileSummaryClass {
    public readonly deployer: number;
    public readonly traders: number;
    constructor(
        deployer,
        traders,
        decimals?,
    ) {
        super();
        this.deployer = this.formatTokenAmount(deployer, decimals || 18);
        this.traders = this.formatTokenAmount(traders, decimals || 18);
    }
}

export interface GasWalletBalanceInterface {
    readonly deployerBalance: string;
    readonly sellersBalance: string;
    readonly buyersBalance: string;
    readonly tradersBalance: string;
}

export interface ProfileTableFiltersInterface {
    readonly quote: QuoteFilterInterface;
    readonly dex?: DexFilterInterface;
    readonly status?: StatusFilterInterface;
}

export interface QuoteFilterInterface {
    readonly quoteFilter: ProfileTableFilterInterface<string>[];
    readonly quoteFilterFn: (list: string[], item: ProfileFullInfoInterface) => boolean;
}

export interface DexFilterInterface {
    readonly dexFilter: ProfileTableFilterInterface<string>[];
    readonly dexFilterFn: (list: string[], item: ProfileFullInfoInterface) => boolean;
}

export interface StatusFilterInterface {
    readonly statusFilter: ProfileTableFilterInterface<string>[];
    readonly statusFilterFn: (list: string[], item: ProfileFullInfoInterface) => boolean;
}

export interface ProfileTableFilterInterface<T> {
    readonly text: string;
    readonly value: T;
    readonly byDefault?: boolean;
}

export type Dexes = {[key in keyof typeof DexNamesEnum]?: ProfileDexesItemInterface};

export interface ProfileDexesItemInterface {
    readonly dex: string;
    readonly network: string;
    readonly networkName: NetworkNamesEnum;
}

export class GetTokenByProjectIdDto {
    constructor(
        public readonly tokenName: string,
        public readonly id: number,
        public readonly projectId: number,
        public readonly existing: ExistingToken,
    ) {}
}

export type ExistingToken = {[key in keyof NetworkNamesEnum | CexNamesEnum]: string};

export class GetProfileByTokenIdRespDto {
    constructor(
        public readonly profileId: number,
        public readonly exchange: DexNamesEnum | CexNamesEnum,
        public readonly quoteName: string,
        public readonly baseName: string,
        public readonly profileType: VaultModesEnum,
        public readonly profileName: string,
        public readonly status: ProfileStatusEnum,
    ) {}

}

export enum ProfileType {
    Cex = 'cex',
    Dex = 'dex'
}

