import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {
    ProjectProfilesFundsInterface,
    ProjectsInterface, ProjectsTradeDataCountInterface,
    ProjectsTradeDataInterface,
    ProjectsWithInfoInterface
} from '@shared/interfaces/projects.interface';
import {ProjectActiveProfilesInterface} from '@shared/interfaces/project-active-profiles.interface';
import {ProjectsStatusEnum} from '@shared/enums/project-statuses.enum';
import {HttpParams} from '@angular/common/http';
import {
    ProjectsSettingsDto
} from "../../page/tenants/modules/tenant/modules/project-settings/models/project-settings-modal.models";
import {ProfileMoveInterface} from "@shared/interfaces/profiles.interface";

@Injectable()
export class TenantApiService {
    constructor(
        private http: BaseService,
    ) {
    }

    getAllProjects(tenantId: number, filterParams: ProjectsStatusEnum[] = [ProjectsStatusEnum.active, ProjectsStatusEnum.archive]): Observable<ProjectsInterface[]> {
        let params = new HttpParams();
        params = params.append('status', filterParams.join(','));

        return this.http.get(`tenants/${tenantId}/projects`, { params });
    }

    getProjectInfo(tenantId: number, projectId: number): Observable<ProjectsWithInfoInterface> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}/info`);
    }

    getProjectsTradesDataCount(tenantId: number, profileIds: number[], projectId: number, dates: string[]): Observable<ProjectsTradeDataCountInterface[]> {
        const params = {from: dates[0], to: dates[1], profilesId: profileIds, projectId};
        return this.http.get(`tenants/${tenantId}/projects/tradeDataCount`, {params});
    }

    getProjectsTradesData(tenantId: number, dates: string[], projectId: number, profilesId: number[], filters: any): Observable<ProjectsTradeDataInterface> {
        const params = {from: dates[0], to: dates[1], projectId, profilesId, page: filters?.page || 1, limit: filters?.limit || 15000};

        return this.http.get(`tenants/${tenantId}/projects/tradeData`, {params});
    }

    createProject(tenantId: number, projectName: string): Observable<ProjectsInterface> {
        return this.http.create(`tenants/${tenantId}/projects`, {
            name: projectName,
        });
    }

    updateProject(tenantId: number, projectId: number, projectName: string): Observable<ProjectsInterface> {
        return this.http.update(`tenants/${tenantId}/projects/${projectId}`, {
            name: projectName,
        });
    }

    updateProjectStatus(tenantId: number, projectId: number, status: ProjectsStatusEnum) {
        return this.http.update(`tenants/${tenantId}/projects/${projectId}/status`, {
            status,
        })
    }

    deleteProject(tenantId: number, projectId: number) {
        return this.http.delete(`tenants/${tenantId}/projects/${projectId}`);
    }

    getProjectActiveProfiles(tenantId: number, projectId: number): Observable<ProjectActiveProfilesInterface[]> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}/activeProfiles`);
    }

    moveProfiles(tenantId: number, projectId: number, body: ProfileMoveInterface) {
        return this.http.update(`tenants/${tenantId}/projects/${projectId}/moveProfiles`, body);
    }

    getProjectsSettings(tenantId: number, projectId: number): Observable<ProjectsSettingsDto> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}/settings`);
    }

    updateProjectsSettings(
        tenantId: number,
        projectId: number,
        projectsSettings: ProjectsSettingsDto,
    ): Observable<ProjectsSettingsDto> {
        return this.http.update(`tenants/${tenantId}/projects/${projectId}/settings`, {
            ...projectsSettings,
        });
    }

    showProjectFunds(tenantId: number, projectId: number): Observable<ProjectProfilesFundsInterface> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}/funds`)
    }
}
