export enum NetworkNamesEnum {
    eth = 'eth',
    bsc = 'bsc',
    heco = 'heco',
    ftm = 'ftm',
    ava = 'ava',
    pol = 'pol',
    'bsc-tst' = 'bsc-tst',
    harmony = 'harmony',
    // goerli = 'goerli',
    optimism = 'optimism',
    arbitrum = 'arbitrum',
    zksync = 'zksync',
    base = 'base',
    celo = 'celo',
    // core = 'core',
    solana = 'solana',
    blast = 'blast',
    // hedera = 'hedera',
    ton = 'ton',
    haqq = 'haqq',
    vtru = 'vtru',
    saakuru = 'saakuru',
    tron = 'tron',
    mode = 'mode',
    mantle = 'mantle',
}

export enum CexNamesEnum {
    gateio = 'gateio',
    binance = 'binance',
    mexc = 'mexc',
    huobipro = 'huobipro',
    kucoin = 'kucoin',
    whitebit = 'whitebit',
    okx = 'okx',
    bitmart = 'bitmart',
    bybit = 'bybit',
    bitget = 'bitget',
}

export enum NetworkChainIdEnum {
    solana = 0,
    eth = 1,
    bsc = 56,
    heco = 128,
    ftm = 250,
    ava = 43114,
    pol = 137,
    'bsc-tst' = 97,
    harmony = 1666600000,
    // goerli = 5,
    optimism = 10,
    arbitrum = 42161,
    zksync = 324,
    base = 8453,
    celo = 42220,
    core = 1116,
    blast = 81457,
    // hedera = 295,
    ton = 100000000001,
    haqq = 11235,
    vtru = 1490,
    saakuru = 7225878,
    tron = 1000,
    mode = 34443,
    mantle = 5000,
}
