import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {catchError, EMPTY, map, Observable, of} from "rxjs";
import {CustomWithdrawDto, EthAccountsInterface, GasWithdrawRespDto} from "@shared/interfaces/eth-accounts.interface";
import {VaultContractStatusesEnum} from "@shared/enums/vault-contract-statuses.enum";
import {VaultContractsInterface} from "@shared/interfaces/vault-contracts.interface";
import {HttpParams} from "@angular/common/http";
import {BlockchainTaskRunRespInterface} from "@shared/interfaces/blockchain-task-run-resp.interface";
import {ProfilesExtendedInfoInterface} from "@shared/interfaces/profile-extended-info.interface";
import {CounterStrategyInterface, TargetStrategyInterface} from "@shared/models/counter-strategy.model";
import {
    CounterStrategyPayload, HolderGrowthStrategyPayload,
    PriceRetentionTargetStrategyPayload,
    SellBuyTargetStrategyPayload,
    TradesVolumeTargetStrategyPayload
} from "@shared/models/strategies-payload.model";
import {RangeStatusEnum} from "@shared/enums/range.enum";
import {Loader} from "@shared/modules/custom-loader/models/decorators";
import {VaultContractFeaturesClass} from "@shared/classes/vault-contract-features.class";
import {ProfileStrategyLogDto, WithdrawLogDto} from "@shared/interfaces/history.interface";
import {MetricsGeneralDataDto} from "@shared/models/profile-dashboard.model";
import {ProfileFulfillmentInfoInterface} from "@shared/interfaces/profile-fulfillment-info.interface";
import {ProfileStatusEnum} from "@shared/enums/profile-status.enum";
import {CexProfileBalancesInterface, ProfileInterface, ProfileType} from "@shared/interfaces/profiles.interface";
import {VaultDeploymentStatusInterface} from "@shared/interfaces/vault-deployment-status.interface";
import {UpdateFullProfileInterface, UpdateProfileInterface} from "@shared/interfaces/update-profile.interface";
import {AddProfileWizardStepsEnum} from "@shared/enums/add-profile-wizard-steps.enum";
import {ApproveProfileInterface} from "@shared/interfaces/approve-profile.interface";
import {EthAccountStatusEnum, EthAccountTypeEnum} from "@shared/enums/eth-account-type.enum";
import {GasWithdrawInterface} from "@shared/interfaces/gas-withdraw.interface";
import {ProfilesInfoInterface} from "@shared/interfaces/profiles-info.interface";
import {cleanObject} from "@shared/helpers/clean-object";
import {PerformanceReportStrategyLogDto} from "@shared/models/performance-report.model";
import {
    CexStrategiesDto,
    CexTradingVolumesStrategiesDto,
    CexTradingVolumesStrategiesPayload
} from "@shared/models/cex-strategies.models";
import {getISODates} from "@helpers";
import {ProfileSettingsDto} from "@shared/models/profile-settings.model";

@Injectable()
export class ProfileApiService {


    constructor(private http: BaseService) {
    }

    getProfileById(tenantId, projectId, profileId, payload = {profileType: 'dex'}): Observable<ProfilesInfoInterface> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}`,
            {params: payload}
        );
    }

    getAccounts(tenantId, profileId, filters): Observable<EthAccountsInterface[]> {
        const payload = filters ? cleanObject(filters) : {loadArchived: false};
        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/ethAccounts`,
            {params: payload});
    }

    getAccountsCount(tenantId, profileId, filters): Observable<number> {
        const payload = cleanObject(filters);
        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/ethAccounts/count`,
            {params: payload});
    }

    deArchiveGasWallet(tenantId, projectId, profileId, gasWalletId: number): Observable<void> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/${gasWalletId}/setAccountStatus`,
            {
                status: EthAccountStatusEnum.active
            }
        )
    }

    reDeployGasWallets(tenantId, projectId, profileId, wallets?: number[], fulfill?: boolean): Observable<GasWithdrawRespDto[]> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/redeployExecutors`,
            wallets ? {accountIds: wallets, fulfill} : {fulfill}
        )
    }

    getVaults(
        tenantId,
        profileId,
        filterParams: VaultContractStatusesEnum[] | null,
    ): Observable<VaultContractsInterface[]> {
        let params = new HttpParams();
        if (filterParams) {
            params = params.append('status', filterParams.join(','));
        }
        return this.http
            .get(`tenants/${tenantId}/profiles/${profileId}/vaultContracts`, { params })
            .pipe(catchError(() => of([])));
    }

    syncProxyReceivers(tenantId, profileId) {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/vaultContracts/syncProxy`,
            {},
        );
    }

    reDeployProxyReceivers(tenantId, profileId): Observable<BlockchainTaskRunRespInterface[]> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/vaultContracts/reDeployProxy`,
            {},
        );
    }

    getDeploymentStatus(
        tenantId,
        projectId,
        profileId,
    ): Observable<VaultDeploymentStatusInterface> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/deploymentStatus`,
        );
    }

    reDeployProxyReceiver(
        tenantId,
        profileId,
        info: { id: number; address: string },
    ): Observable<BlockchainTaskRunRespInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/vaultContracts/reDeploySingleProxy`,
            info,
        );
    }

    updateVaultSettings(
        tenantId,
        projectId,
        profileId,
        settings,
    ): Observable<ProfilesExtendedInfoInterface> {
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/changeContractSettings`,
            settings,
        )
    }

    getProfileStrategies(tenantId, profileId): Observable<(CounterStrategyInterface | TargetStrategyInterface)[]> {
        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/ranges`);
    }

    getProfileCexStrategies(tenantId, profileId): Observable<CexStrategiesDto[]> {
        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/cex-strategies`);
    }

    getProfileBalances(tenantId, projectId, profileId): Observable<CexProfileBalancesInterface> {
        return this.http.get(`tenants/${tenantId}/project/${projectId}/profiles/${profileId}/cex-balances`);
    }

    getRangeById(tenantId, profileId, rangeId): Observable<CounterStrategyInterface | TargetStrategyInterface> {
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}`,
        );
    }

    getCexStrategyById(tenantId, profileId, rangeId): Observable<CexStrategiesDto> {
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/cex-strategies/${rangeId}`,
        );
    }

    createCounterStrategy(tenantId, profileId, payload: CounterStrategyPayload): Observable<CounterStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/counter`,
            payload,
        );
    }

    createSellBuyTargetStrategy(tenantId, profileId, payload: SellBuyTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/tradeback`,
            payload,
        );
    }

    createPriceRetentionTargetStrategy(tenantId, profileId, payload: PriceRetentionTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/priceRetention`,
            payload,
        );
    }

    createTradesVolumeTargetStrategy(tenantId, profileId, payload: TradesVolumeTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/tradesVolume`,
            payload,
        );
    }

    createHolderGrowthStrategy(tenantId, profileId, payload: HolderGrowthStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/holders`,
            payload,
        );
    }

    createMakerGrowthStrategy(tenantId, profileId, payload: HolderGrowthStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ranges/makers`,
            payload,
        );
    }

    createCexTradingVolumeStrategy(tenantId, profileId, payload: CexTradingVolumesStrategiesPayload): Observable<CexTradingVolumesStrategiesDto> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/cex-strategies/tradesVolume`,
            payload,
        );
    }

    updateCounterStrategy(tenantId, profileId, rangeId, payload: CounterStrategyPayload): Observable<CounterStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/counter`,
            payload,
        );
    }

    updateSellBuyTargetStrategy(tenantId, profileId, rangeId, payload: SellBuyTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/tradeback`,
            payload,
        );
    }

    updatePriceRetentionTargetStrategy(tenantId, profileId, rangeId, payload: PriceRetentionTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/priceRetention`,
            payload,
        );
    }

    updateTradesVolumeTargetStrategy(tenantId, profileId, rangeId, payload: TradesVolumeTargetStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/tradesVolume`,
            payload,
        );
    }

    updateHolderGrowthStrategy(tenantId, profileId, rangeId, payload: HolderGrowthStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/holders`,
            payload,
        );
    }

    updateMakerGrowthStrategy(tenantId, profileId, rangeId, payload: HolderGrowthStrategyPayload): Observable<TargetStrategyInterface> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/makers`,
            payload,
        );
    }

    updateCexTradingVolumeStrategy(tenantId, profileId, rangeId, payload: CexTradingVolumesStrategiesPayload): Observable<CexTradingVolumesStrategiesDto> {
        return this.http.update(
            `tenants/${tenantId}/profiles/${profileId}/cex-strategies/${rangeId}/tradesVolume`,
            payload,
        );
    }

    refreshStrategyStatus(tenantId, profileId, rangeId, status?: RangeStatusEnum): Observable<CounterStrategyInterface | TargetStrategyInterface> {
        return this.http.create(`tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/refresh`, {status});
    }

    refreshCexStrategyStatus(tenantId, profileId, rangeId, status?: RangeStatusEnum): Observable<CexStrategiesDto> {
        return this.http.create(`tenants/${tenantId}/profiles/${profileId}/cex-strategies/${rangeId}/refresh`, {status});
    }

    updateStrategyStatus(tenantId, profileId, rangeId, statusToChange: RangeStatusEnum) {
        return this.http.update(`tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}/status`, {
            status: statusToChange,
        })
    }

    updateCexStrategyStatus(tenantId, profileId, rangeId, statusToChange: RangeStatusEnum) {
        return this.http.update(`tenants/${tenantId}/profiles/${profileId}/cex-strategies/${rangeId}/status`, {
            status: statusToChange,
        })
    }

    deleteProfileRange(tenantId, profileId, rangeId) {
        return this.http.delete(
            `tenants/${tenantId}/profiles/${profileId}/ranges/${rangeId}`,
        );
    }

    deleteCexProfileStrategy(tenantId, profileId, strategyId) {
        return this.http.delete(`tenants/${tenantId}/profiles/${profileId}/cex-strategies/${strategyId}`);
    }

    @Loader()
    getProfileExtendedInfo(
        tenantId,
        projectId,
        profileId,
        profileType: ProfileType,
    ): Observable<ProfilesExtendedInfoInterface> {
        return this.http
            .get(`tenants/${tenantId}/project/${projectId}/profiles/${profileId}/info`, {params: {profileType}})
            .pipe(
                map((info) => {
                    return {
                        ...info,
                        version: new VaultContractFeaturesClass(info.version),
                    };
                }),
            );
    }

    getProfileLogsCount(
        tenantId,
        projectId,
        profileId,
        from: string,
        to: string,
        filters?: any,
        ranges?: number[]
    ): Observable<number> {
        const params = ranges ? {from , to, ranges} : {from, to};
        filters && Object.keys(filters).forEach(key => {
            if (key === 'dates') {
                return;
            }
            params[key] = filters[key];
        });

        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/transactionLogsCount`,
            {params}
        );
    }

    getProfileLogs(
        tenantId,
        projectId,
        profileId,
        from: string,
        to: string,
        filters: any,
        ranges?: number[]
    ): Observable<ProfileStrategyLogDto[]> {
        const params = ranges ? {from , to, ranges} : {from, to};
        filters && Object.keys(filters).forEach(key => {
            if (key === 'dates') {
                return;
            }
            params[key] = filters[key];
        });
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/transactionLogs`,
            {params}
        );
    }

    getProfileGeneralMetrics(
        tenantId,
        profileId,
        from: string,
        to: string,
    ): Observable<MetricsGeneralDataDto> {
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/general`,
            {params: {from, to}}
        );
    }

    getProfileFulfillmentInfo(
        tenantId,
        projectId,
        profileId,
    ): Observable<ProfileFulfillmentInfoInterface> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/fulfillmentInfo`,
        );
    }

    updateProfileStatus(
        tenantId,
        projectId,
        profileId,
        status: ProfileStatusEnum,
        profileType: ProfileType,
    ): Observable<ProfileInterface> {
        return this.http.update(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/status`,
            { status, profileType },
        );
    }

    updateProfile(
        tenantId,
        projectId,
        profileId,
        updateProfile: UpdateProfileInterface,
        profileType?: ProfileType
    ): Observable<ProfileInterface> {
        const update =  cleanObject(updateProfile);
        return this.http.update(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/short`,

            {...update, telegramTags: updateProfile?.telegramTags || [], profileType},
        );
    }

    updateProfileWizardSteps(
        tenantId: number,
        projectId,
        profileId,
        wizardSetupStep: AddProfileWizardStepsEnum,
    ): Observable<void> {
        return this.http.update(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/wizardSetupSteps`,
            { wizardSetupStep },
        );
    }

    updateFullProfile(
        tenantId,
        projectId,
        profileId,
        updateProfile: UpdateFullProfileInterface,
    ): Observable<ProfileInterface> {
        const update = cleanObject(updateProfile);
        return this.http.update(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/full`,
            update,
        );
    }

    approveProfile(
        tenantId,
        projectId,
        profileId,
    ): Observable<ApproveProfileInterface> {
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/approve`,
            undefined,
        );
    }

    getAccountsByType(
        tenantId,
        profileId,
        types: EthAccountTypeEnum[],
    ): Observable<EthAccountsInterface[]> {
        let params = new HttpParams();
        params = params.append('type', types.join(','));

        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/ethAccounts`, {
            params,
        });
    }

    getExecutorAccountsCount(
        tenantId,
        profileId,
        filters?
    ): Observable<number> {
        const payload = filters ? cleanObject(filters) : {loadArchived: false};
        return this.http.get(`tenants/${tenantId}/profiles/${profileId}/ethAccounts/tradersCount`,
            {params: payload});
    }

    addExecutors(tenantId, profileId): Observable<void> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/addExecutors`,
            {},
        );
    }

    approveExecutors(tenantId, profileId): Observable<void> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/approveAllExecutorsMode`,
            {},
        );
    }

    withdrawFunds(tenantId, profileId, payload: CustomWithdrawDto): Observable<GasWithdrawRespDto[]> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/customWithdraw`,
            payload,
        );
    }

    updateCritical(tenantId, profileId): Observable<void> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/updateCritical`,
            {},
        );
    }

    fillGas(
        tenantId,
        projectId,
        profileId,
        ethAccountsIds: number[],
        leaveOnDeployer: number,
    ): Observable<BlockchainTaskRunRespInterface[]> {
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/distributeGas`,
            {
                ethAccountsIds,
                leaveOnDeployer,
            },
        );
    }

    getPerformanceReportCount(
        tenantId,
        projectId,
        profileId,
        from: string,
        to: string,
        ranges: number[] | null,
        filters?: any,
    ): Observable<number> {
        const params = ranges ? {from , to, ranges} : {from, to};
        filters && Object.keys(filters).forEach(key => {
            params[key] = filters[key];
        });
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/reportCount`,
            { params },
        )
    }

    getPerformanceReport(
        tenantId,
        projectId,
        profileId,
        from: string,
        to: string,
        ranges: number[] | null,
        filters?: any,
    ): Observable<PerformanceReportStrategyLogDto[]> {
        const params = ranges ? {from , to, ranges} : {from, to};
        filters && Object.keys(filters).forEach(key => {
            params[key] = filters[key];
        });
        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/report`,
            { params },
        )
    }

    getWithdrawLogs(
        tenantId,
        profileId,
        dates,
    ): Observable<WithdrawLogDto[]> {
        const newDates = getISODates(dates);
        const params = {profileId, from: newDates[0], to: newDates[1]}

        return this.http.get(
            `tenants/${tenantId}/profiles/${profileId}/metrics/withdraw-logs`,
            { params },
        )
    }

    validateWalletsKeys(
        tenantId: number,
        profileId: number,
        wallets: string[],
    ): Observable<number[]> {
        return this.http.create(
            `tenants/${tenantId}/profiles/${profileId}/ethAccounts/validateWallets`,
            wallets ? {walletKeys: wallets} : {},
        );
    }

    setProfileSettings(
        tenantId: number,
        projectId: number,
        profileId: number,
        settings: any,
    ): Observable<void> {
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/settings`,
            {settings}
        )
    }

    getProfileSettings(
        tenantId: number,
        projectId: number,
        profileId: number,
    ): Observable<ProfileSettingsDto> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/settings`,
        )
    }

    getViewProfileInfo(
        profileId: number,
    ): Observable<any> {
        return this.http.get(`profile-dashboard/${profileId}`)
    }
}
